import { GOOGLE_ANALYTICS_ID, APPLE_APP_ID } from "../config";

import * as React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import {
  Analytics,
  OpenGraph,
  Layout,
  DinoSection,
  DinosSection,
  Footer,
} from "../components";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface DinoProperties {
  name: string;
  value: string;
}

interface Dino {
  name: string;
  description: string;
}

interface Props {
  data: any;
  pageContext: { locale: string; pagePath: string };

  meta_keywords: string;

  header_title: string;
  header_logo_alt: string;
  header_logo_icon: Image;
  header_navigation: {
    caption: string;
    link: string;
  }[];

  product_title: string;
  product_subtitle: string;
  product_image: Image;
  product_image_alt: string;
  product_description_part: string;

  dinos_title: string;
  dinos_image: {
    childImageSharp: {
      fluid: { originalImg: string; src: string; srcSet: string };
    };
  };
  dinos: {
    name: string;
    description: string;
    shortDescription: string;
    bigImage: Image;
    image: Image;
    alt: string;
    link: string;
    properties: DinoProperties[];
  }[];
}

export const query = graphql`
  query($regex: String!) {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: $regex } }) {
      edges {
        node {
          id
          frontmatter {
            meta_keywords

            header_title
            header_logo_icon {
              childImageSharp {
                fluid {
                  src
                  srcSet
                }
              }
            }
            header_logo_alt
            header_navigation {
              caption
              link
            }

            dinos_title
            dinos_image {
              childImageSharp {
                fluid {
                  originalImg
                  src
                  srcSet
                }
              }
            }
            dinos {
              name
              description
              shortDescription
              bigImage {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                  }
                }
              }
              image {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                  }
                }
              }
              alt
              link
              properties {
                name
                value
              }
            }

            footer_privacy
            footer_terms
          }
          excerpt
        }
      }
    }
  }
`;

class TrexPage extends React.Component<Props, {}> {
  public render() {
    const { locale, pagePath } = this.props.pageContext;
    const activeDino =
      pagePath
        .split("/")[1]
        .charAt(0)
        .toUpperCase() + pagePath.slice(2).split("/")[0];
    return (
      <>
        <Analytics googleAnalyticsId={GOOGLE_ANALYTICS_ID} />
        {this.props.data.allMarkdownRemark.edges.map(({ node }: any) => (
          <div key={node.id}>
            {node.frontmatter.dinos
              .filter(dino => dino.name == activeDino)
              .map(({ name, description }: Dino) => (
                <>
                  <Helmet>
                    <link
                      rel="origin"
                      href={`https://monsterparkbox.com/${name.toLowerCase()}`}
                    />
                    <link
                      rel="canonical"
                      href={`https://monsterparkbox.com/${name.toLowerCase()}`}
                    />
                    <title>{name}</title>
                  </Helmet>
                  <OpenGraph
                    title={name}
                    description={description}
                    appleAppId={APPLE_APP_ID}
                  />
                  <Layout
                    locale={locale}
                    meta_description={description}
                    meta_keywords={node.frontmatter.meta_keywords}
                    header_title={node.frontmatter.header_title}
                    header_logo_alt={node.frontmatter.header_logo_alt}
                    header_logo_icon={
                      node.frontmatter.header_logo_icon.childImageSharp.fluid
                        .src
                    }
                    header_navigation={node.frontmatter.header_navigation}
                  >
                    <DinoSection
                      activeDino={activeDino}
                      dinos={node.frontmatter.dinos}
                    />
                    <DinosSection
                      locale={locale}
                      dinos_title={node.frontmatter.dinos_title}
                      dinos_image={node.frontmatter.dinos_image}
                      dinos={node.frontmatter.dinos}
                      activeDino={activeDino}
                    />
                    <Footer
                      footer_privacy={node.frontmatter.footer_privacy}
                      footer_terms={node.frontmatter.footer_terms}
                    />
                  </Layout>
                </>
              ))}
          </div>
        ))}
      </>
    );
  }
}

export default TrexPage;
